export const PROJECT_DEFAULT_DISPLAY = 6;

export const srConfig = ({
  origin = 'bottom', 
  delay = 200, 
  viewFactor = 0.25,
}) => ({
  origin,
  distance: '20px',
  duration: 500,
  delay,
  rotate: { x: 0, y: 0, z: 0 },
  opacity: 0,
  scale: 1,
  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  mobile: true,
  reset: false,
  useDelay: 'onload',
  viewFactor,
  viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
});